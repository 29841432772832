import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteTitle
          siteCover
          authorName
          siteDescription
          headerTitle
          websiteHost {
            name
            url
          }
        }
      }
    }
  `)
  return result.site.siteMetadata
}

export default useSiteMetadata
